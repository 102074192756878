
.letterEff{
	height:100vh;
	width:100vw;
	display: flex ; 
	justify-content: center;
	align-items: center;
	
	
}

.block {
	position: fixed;
	border-bottom: 1px solid #fff;

	span{
			display: inline-block;
			font-weight: 700;
			font-size: 2em;
			color: #fff;
			transition: all 0.5s ease-in-out;
			&.spaced {
				padding-left: 0;
			}
			&.ghost {
				opacity: 0;
				max-width: 0;
			}
	}
	
	&:hover {
		border-bottom: 1px solid transparent;
		
		span {
			opacity: 1;
			max-width: 1.2em;
			color:#ff6611;
		}
	}
}

.circileEff{
	width:200px;
	
	svg{animation: rotateZ 5000ms linear infinite paused;
		pointer-events: none;
	}
	&:hover{
		svg{
			
			animation-play-state: running;
		}
	} 
}


@keyframes rotateZ {
		0%{
			transform: rotateY(0);
		}
		50%{
			transform: perspective(10px) rotateY(180deg);
		}
		100%{
			transform: rotateY(0);
		}
}