body{
  background: rgba(47, 47, 47, 1);
  color: #fff;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.effTxt{
  font-size: 15em;
  span{
    color:rgba(47, 47, 47, 0);
    opacity: 0;
    transition: all 0.3s linear;
  }
}

